// @flow

import {
  initializeAnalytics,
  trackPageChange,
  trackAction as ta,
} from '@e4/web-analytics';
import { history } from '@e4/ebanking-web-utils';

let enabled = true;

export function configureAnalytics() {
  const {
    REACT_APP_ADOBE_ANALYTICS_RSID,
    REACT_APP_ADOBE_ANALYTICS_ID,
    REACT_APP_ADOBE_ANALYTICS_DOMAIN,
    REACT_APP_ANALYTICS_ENABLED,
  } = process.env;

  enabled = REACT_APP_ANALYTICS_ENABLED === 'true';
  if (!enabled) return;

  if (
    typeof REACT_APP_ADOBE_ANALYTICS_RSID === 'string' &&
    typeof REACT_APP_ADOBE_ANALYTICS_ID === 'string'
  ) {
    initializeAnalytics(
      REACT_APP_ADOBE_ANALYTICS_RSID,
      REACT_APP_ADOBE_ANALYTICS_ID,
      REACT_APP_ADOBE_ANALYTICS_DOMAIN
    );
    trackPageChange(history.location.pathname);
    history.listen((location) => trackPageChange(location.pathname));
  } else {
    console.error('RSID/ID has not been set, hence analytics will not work'); //eslint-disable-line
  }
}

export function trackAction(actionId: string, data: Object = {}) {
  enabled && ta(history.location.pathname, actionId, data);
}
