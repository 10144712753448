import React, { Component } from 'react';
import {
  UIProvider,
  themes,
  TopBarContentProvider,
  ServiceBarProvider,
  SimpleModalDialog,
} from '@e4/ui';
import translate from '@e4/intl/translate.macro';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { history } from '@e4/ebanking-web-utils';
import { COUNTRY_CODE } from '@e4/intl';
import { InactivityTimer } from '@e4/ebanking-web-logoff';
import configureRequest from '../request';
import { configureAnalytics, trackAction } from '../analytics';
import DevTool from './DevTool';
import WebMenu from '@e4/ebanking-web-menu';
import routes from '../routes';
import Logo from '../assets/logo.svg';

export class App extends Component {
  state = {
    isDialogOpen: false,
    dialogTitle: '',
    dialogText: '',
    dialogButtons: [],
  };

  constructor(props, context) {
    super(props, context);

    configureRequest();
    configureAnalytics();
  }

  componentDidMount() {
    InactivityTimer.addInactivityListeners(this.toggleInactivityWarningDialog);
  }

  componentWillUnmount() {
    InactivityTimer.removeInactivityListeners();
    InactivityTimer.clear();
  }

  toggleInactivityWarningDialog = (data) => {
    if (data) {
      this.setState({
        isDialogOpen: data.isDialogOpen,
        dialogTitle: data.dialogTitle,
        dialogText: data.dialogText,
        dialogButtons: data.dialogButtons,
      });
    } else {
      this.setState({
        isDialogOpen: false,
        dialogTitle: '',
        dialogText: '',
        dialogButtons: [],
      });
    }
  };

  renderMenu = () => {
    return <WebMenu />;
  };

  render() {
    return (
      <ThemeProvider theme={themes.defaultTheme}>
        <UIProvider
          logo={Logo}
          onAnalyticsEvent={(id) => {
            trackAction(id);
          }}
          appName={translate('appName')}
        >
          <TopBarContentProvider renderTopBarContent={this.renderMenu}>
            <ServiceBarProvider {...serviceBarProps}>
              <Router history={history}>
                {routes}
                <DevTool />
                <SimpleModalDialog
                  isOpen={this.state.isDialogOpen}
                  title={this.state.dialogTitle}
                  text={this.state.dialogText}
                  buttons={this.state.dialogButtons}
                />
              </Router>
            </ServiceBarProvider>
          </TopBarContentProvider>
        </UIProvider>
      </ThemeProvider>
    );
  }
}

const serviceBarProps = {
  wealthCustomer: false,
  showCallUs: true,
  showChat: true,
  showBookMeeting: COUNTRY_CODE.toLowerCase() !== 'fi',
  callUsPhoneNumberText: translate('servicebar.callUsPhoneNumberText'),
  callUsCallPrivatBanker: translate('servicebar.callUsCallPrivatBanker'),
  callUsPanelHeader: translate('servicebar.callUsPanelHeader'),
  callUsOpeningHours: translate('servicebar.callUsOpeningHours'),
  callUsInformation: translate('servicebar.callUsInformation'),
  callUsButtonLabel: translate('servicebar.callUsButtonLabel'),
  chatButtonLabel: translate('servicebar.chatButtonLabel'),
  chatButtonLink: translate('servicebar.chatButtonLink'),
  bookMeetingButtonLabel: translate('servicebar.bookMeetingButtonLabel'),
  bookMeetingButtonLink: translate('servicebar.bookMeetingButtonLink'),
};

export default App;
