import request from 'db-request';
import { InactivityTimer } from '@e4/ebanking-web-logoff';
import { LOCALE } from '@e4/intl';

export default function configureRequest() {
  const {
    REACT_APP_API_WAF_BASE_URL,
    REACT_APP_SERVER_CLIENT_ID,
    REACT_APP_SERVER_CLIENT_SECRET,
  } = process.env;

  // ToDo: Once majority or all endpoints are moved out of WAF then replace this with REACT_APP_API_MARKET_BASE_URL and remove all base URL configs in requests.
  request.setServerConfig({
    baseUrl: REACT_APP_API_WAF_BASE_URL,
    clientId: REACT_APP_SERVER_CLIENT_ID,
    clientSecret: REACT_APP_SERVER_CLIENT_SECRET,
  });
  request.setAppCulture(LOCALE);

  request.addPreRequestHandler((config) => {
    //enable cookies over CORS
    config['credentials'] = 'include';

    // Referer header should be 'my.danskebank' in DEV, so that Backend can see that it's a WEB3 client
    if (process.env.NODE_ENV === 'development') {
      config['referrerPolicy'] = 'unsafe-url';
      config['referrer'] = 'my.danskebank';
    }

    return config;
  });

  request.addPostRequestHandler((response, url, config) => {
    if (
      url !== '/security/gateway/v1/session/validate' && // Ignored to skip infinite loop
      url !== '/security/gateway/logoff' && // Ignored as there is no session after logoff
      url !== '/signing/signingsystem/extend' && // Ignored as extend is used only before ASHE logon
      url !== '/api/tooling/v2/features/evaluate/public' && // Ignored as no session for public endpoint
      url !== '/authentication/sectorsolutions/swedpoll' && // Ignored as swedpoll is called too often
      url !== '/security/v1/session/ashe/init' // Ignored as there is no session before ASHE logon
    ) {
      const sessionId =
        window.sessionStorage.getItem('sessionId') || validateAndCacheSession();

      if (sessionId) {
        InactivityTimer.toggleInactivityTimer(true, sessionId);
      }
    }

    return response;
  });

  const validateAndCacheSession = () => {
    request
      .post(
        '/security/gateway/v1/session/validate',
        {},
        {
          baseUrl: process.env.REACT_APP_API_MARKET_BASE_URL,
          responseType: 'json',
        }
      )
      .then((data) => {
        if (data?.SessionId && data?.SecurityLevel > 0) {
          window.sessionStorage.setItem('sessionId', data.SessionId);
          return data.SessionId;
        }
        return null;
      })
      .catch((error) => {
        if (error.status === 401) window.sessionStorage.removeItem('sessionId');
        return null;
      });
  };
}
