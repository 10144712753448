import React from 'react';
import { Route } from 'react-router-dom';
import { GridVisualizer } from '@e4/ui';

// TODO: Disable this in PROD builds?

export default class DevTool extends React.Component {
  render() {
    return (
      <Route
        render={({ location }) => {
          if (location.search.indexOf('?dev') === -1) {
            return null;
          }
          return <GridVisualizer />;
        }}
      />
    );
  }
}
